import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const InfoBox = makeShortcode("InfoBox");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const DoDontComponent = makeShortcode("DoDontComponent");
const Do = makeShortcode("Do");
const AdaptiveImage = makeShortcode("AdaptiveImage");
const ImageCaption = makeShortcode("ImageCaption");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton link="/content" mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Integrations</Heading>
      <Text mdxType="Text">
  Strategic use of components, like integration banners or empty states, at the
  right moment in the flow ensures complementary tool discovery without
  disrupting the workflow.
      </Text>
      <InfoBox withIcon variant="discovery" mdxType="InfoBox">
  We're in the early stages of tailoring industry best practices to our unique
  product needs. These guidelines will evolve with more patterns, and we welcome
  all ideas.
      </InfoBox>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Common scenarios</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use integration patterns for apps that integrate directly or complement each
    other.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Direct integrations deepen the usage of both apps via data sharing or
    cross-functionality.<br></br>For example, one app can display charts based on
    data from another app.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Complementary apps can address specific user needs without direct
    interaction.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">
    Make it timely, contextual, and valuable
  </DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Provide a smooth, non-intrusive experience by suggesting apps that match
    user goals.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Place your suggestions at the right time in the journey when they add value.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Explain how the recommended app enhances or complements the current
    experience.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't be spammy. Our recommendation should feel like an invitation, not a
    nuisance.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <Heading variant="h700" mdxType="Heading">Integration banners</Heading>
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Placement and visibility</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Display banners in high-visibility areas or near relevant features.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Ensure they don't overlap with other in-app messages and interface elements.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid making components bounce, glow, or pulse to prevent overwhelming
    users.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't display integration banners in error states.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Headings</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use direct, concise, and motivating language.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Highlight the primary integration value and include the recommended app
    name.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Prioritize highlighting the benefit, not the actions required to achieve it.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    At times, people might also seek specific functionalities.<br></br>
    Always evaluate what's most relevant and adjust your focus accordingly.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`blockquote`} mdxType="Do">
    “Unlock insights” or “Streamline planning” tells people why they might be
    interested in creating charts, dragging tasks, etc.
  </Do>
  <Do variant={`blockquote`} mdxType="Do">
    “Plan work with Gantt charts“ can work better if people fully understand the
    value and need specific functionality.
  </Do>
      </DoDontComponent>
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Body</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Start with choosing the recommended app's specific function, feature, or
    capability.<br></br> This will make your message more tangible and relatable.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    You can also highlight an example of a use case if applicable.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Tie any of the above to the direct benefit or outcome people will
    experience.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Aim to fit your description into 2 lines.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't repeat details from the heading to avoid wasting space.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/73effa64-4483-43d1-a7ec-ff602021bc78" darkSrc="https://github.com/user-attachments/assets/75fd7254-f8e7-445b-9875-6937fdb4ec70" alt="Template for structuring integration banner content." mdxType="AdaptiveImage" />
      <ImageCaption mdxType="ImageCaption">
  <b>Content template</b>
  <br></br>Using a template for integration banners ensures all key details are
  included, helps maintain consistency, and prevents omissions.
      </ImageCaption>
      <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/4e8b8bff-2585-436b-8fe0-421993a95f88" darkSrc="https://github.com/user-attachments/assets/2ae04cd5-5900-4d34-98c7-db89b63ba36b" alt="An example of an integration message using the template." mdxType="AdaptiveImage" />
      <ImageCaption mdxType="ImageCaption">
  <b>Example 1</b>
  <br></br>A concise and value-filled heading grabs attention and inspires
  action. Highlighting specific capabilities helps visualize how the app
  supports everyday tasks.
      </ImageCaption>
      <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/7a9ae75d-4845-4780-8323-0fb4797d7260" darkSrc="https://github.com/user-attachments/assets/f3e169d0-5529-4dd1-8c19-8149329aefa8" alt="An example of an integration message using the template." mdxType="AdaptiveImage" />
      <ImageCaption mdxType="ImageCaption">
  <b>Example 2</b>
  <br></br>Experimenting with structure or shifting the focus might be a way to
  go for your specific flow. Templates ensure consistency but are flexible and
  can be adapted to meet all priorities.
      </ImageCaption>
      <hr />
      <Text mdxType="Text">Share your feedback on the #design-systems channel in Slack.</Text>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      